import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getUserActiveSubscriptionDetails } from "../subscription/subscriptionSlice";
import { selectSubscription } from "../subscription/selectors";

import styles from "./pricing.module.css";

export default function Pricing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hoveredButton, setHoveredButton] = useState(null);
  const [activeButton, setActiveButton] = useState("button1");

  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleClick = (path, buttonId) => {
    navigate(path);
    setActiveButton(buttonId);
  };

  const subscription = useSelector(selectSubscription);

  useEffect(() => {
    dispatch(getUserActiveSubscriptionDetails());
  }, [dispatch]);

  const { startDate, endDate, tariff, currency, amount } = subscription;

  const formatAmount = (value) => {
    const amountString = value.toString();
    return `${amountString.slice(0, -2)}.${amountString.slice(-2)}`;
  };

  const formatDateTime = (isoDate) => {
    const date = new Date(isoDate);

    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}  UTC`;
  };

  return (
    <div className={styles.mainBox}>
      <div className={styles.leftBarContainer}>
        <div className={styles.backContainer}>
          <img src="/circle.webp" alt="buttonBack" />
        </div>
        <div>
          <button
            className={`${styles.buttonBack} ${activeButton === "buttonBack" ? styles.buttonBackPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/mySynthetic", "buttonBack")}
            id="backToMySyntheticButton"
          >
            <div className={styles.buttonBackContainer}>
              <img src="/arrow-right.webp" alt="arrow" className={styles.arrowIcon} />
              <div className={styles.buttonBackTextContainer}>
                <span>back</span>
              </div>
            </div>
          </button>
        </div>
        <div>
          <button
            className={`${styles.button1} ${activeButton === "button1" || hoveredButton === "button1" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/payment/pricing", "button1")}
            onMouseEnter={() => handleMouseEnter("button1")}
            onMouseLeave={handleMouseLeave}
            id="pricingButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Pricing</span>
              </div>
              {activeButton === "button1" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button1" />
                </div>
              )}
            </div>
          </button>
          <button
            className={`${styles.button2} ${activeButton === "button2" || hoveredButton === "button2" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/payment/history", "button2")}
            onMouseEnter={() => handleMouseEnter("button2")}
            onMouseLeave={handleMouseLeave}
            id="historyButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>History</span>
              </div>
              {hoveredButton === "button2" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button2" />
                </div>
              )}
            </div>
          </button>
          <button
            className={`${styles.button3} ${activeButton === "button3" || hoveredButton === "button3" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/payment/info", "button3")}
            onMouseEnter={() => handleMouseEnter("button3")}
            onMouseLeave={handleMouseLeave}
            id="infoButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Info</span>
              </div>
              {hoveredButton === "button3" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button3" />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
      <div className={styles.paymentCard}>
        <h3>Pricing</h3>
        {!!startDate && (
          <>
            <div className={styles.tariff}>Tariff: {tariff}</div>
            <div className={styles.amount}>
              Amount: {formatAmount(amount)} {currency}
            </div>
            <div className={styles.date}>
              {t("startDate")}: {formatDateTime(startDate)}
            </div>
            {endDate ? (
              <div className={styles.date}>
                {t("endDate")}: {formatDateTime(endDate)}
              </div>
            ) : (
              <div className={styles.date}>{t("endDate")}: - </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
