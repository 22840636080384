import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import store from "../../app/store";
import { loadAuthUser, loadNewAccessToken, logout } from "./authSlice";

const checkAuth = async () => {
  try {
    if (!store.getState().auth.user) {
      if (!Cookies.get("refreshToken")) {
        store.dispatch(logout());
        return false;
      }

      if (Cookies.get("refreshToken") && !Cookies.get("accessToken")) {
        await store.dispatch(loadNewAccessToken());
      } else if (Cookies.get("accessToken")) {
        const decoded = jwtDecode(Cookies.get("accessToken"));
        if (decoded.exp && decoded.exp - moment().unix() < 10) {
          await store.dispatch(loadNewAccessToken());
        }
      }

      await store.dispatch(loadAuthUser());
    }
    return true;
  } catch (error) {
    store.dispatch(logout());
    return false;
  }
};

export default checkAuth;
