// src/ga.js
import ReactGA from "react-ga4";

const initGA = () => {
  ReactGA.initialize("G-2K6BQ4BEM4");
};

const logPageView = (page) => {
  ReactGA.send({ hitType: "pageview", page });
};

export { initGA, logPageView };
