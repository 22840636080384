import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import NewStandardImages from "../../admin_panel/NewStandardImages";

export default function AuthAdminRoute() {
  if (!Cookies.get("adminToken").match(true)) {
    return <Navigate to="/" replace />;
  } else {
    return <NewStandardImages />;
  }
}
