import React, { useCallback, useEffect, useState } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch, useSelector } from "react-redux";
import styles from "./newSharedImages.module.css";
import PreLoading from "../../components/pre_loading/PreLoading";
import * as selector from "../user_synthetic/selectors";
import { uploadSharedAvatar } from "../user_synthetic/userSyntheticSlice";
import AccountNavbar from "../account_layout/AccountNavbar";

function NewStandardImages() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(null);
  const [activeBtnPicture, setActiveBtnPicture] = useState(null);
  const [newAvatar, setNewAvatar] = useState(null);
  const isLoadingUploadAvatar = useSelector(selector.selectIsLoadingUploadAvatar);

  const handleClickBtn = (currSection, buttonId) => {
    if (currSection === "pictureSection") {
      setActiveBtnPicture(buttonId);
    }
  };

  const handleOpenModal = (modal) => {
    setOpenModal(modal);
  };

  const handleSubmitUpload = useCallback(async () => {
    if (!newAvatar) {
      console.error("No file selected");
      return;
    }
    const dispatchResult = await dispatch(uploadSharedAvatar(newAvatar));
    if (uploadSharedAvatar.fulfilled.match(dispatchResult)) {
      setOpenModal(null);
      setActiveBtnPicture(null);
    }
    if (uploadSharedAvatar.rejected.match(dispatchResult)) {
      console.error("upload error");
    }
  }, [dispatch, newAvatar]);

  return (
    <div>
      <AccountNavbar />
      <div>
        <div className={styles.text}>Add image, which will be used by all users:</div>
        <button
          className={`${styles.btn} ${activeBtnPicture === "btnPictureUpload" ? styles.activeBtnByCreate : ""}`}
          id="btnPictureUpload"
          type="button"
          onClick={() => {
            handleClickBtn("pictureSection", "btnPictureUpload");
            handleOpenModal("uploadModal");
          }}
        >
          <UploadIcon />
          Upload new avatar
        </button>
      </div>
      {openModal === "uploadModal" && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            {isLoadingUploadAvatar ? (
              <PreLoading />
            ) : (
              <>
                <h1>Upload new avatar</h1>
                <div className={styles.requirementsBox}>
                  <div className={styles.requirementsTextBox}>
                    <p className={styles.requirementsTextHead}>Photo requirements for optimized results:</p>
                    <p className={styles.requirementsText}>
                      <DoneIcon style={{ color: "green" }} />
                      <span>Medium shot, frontal facing</span>
                    </p>
                    <p className={styles.requirementsText}>
                      <DoneIcon style={{ color: "green" }} />
                      Neutral facial expression, closed mouth
                    </p>
                    <p className={styles.requirementsText}>
                      <DoneIcon style={{ color: "green" }} />
                      File Format: png, jpeg, jpg
                    </p>
                    <p className={styles.requirementsText}>
                      <DoneIcon style={{ color: "green" }} />
                      Recommended image size is - 512X512 pixels
                    </p>
                    <p className={styles.requirementsText}>
                      <DoneIcon style={{ color: "green" }} />
                      Good and solid lighting
                    </p>
                    <p className={styles.requirementsText}>
                      <DoneIcon style={{ color: "green" }} />
                      Size - up to 10Mb
                    </p>
                    <p className={styles.requirementsText}>
                      <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                      No face occlusions
                    </p>
                  </div>
                  <img className={styles.exampleImg} src="/syntheticAvatar.webp" alt="example" />
                </div>
                <form className={styles.formUpload} method="POST" encType="multipart/form-data">
                  <input
                    className={styles.fileInput}
                    type="file"
                    id="fileInput"
                    name="file"
                    accept="image/*"
                    onChange={(e) => setNewAvatar(e.target.files[0])}
                  />
                  {newAvatar === null ? (
                    <button className={`${styles.btn} ${styles.noActiveBtn}`} type="button" id="submitUploadBtn">
                      Upload
                    </button>
                  ) : (
                    <button
                      className={`${styles.btn} ${styles.submitUploadBtn} `}
                      type="button"
                      id="submitUploadBtn"
                      onClick={handleSubmitUpload}
                    >
                      Upload
                    </button>
                  )}
                </form>
                <button
                  className={`${styles.btn} ${styles.modalUploadCancelBtn}`}
                  type="button"
                  id="modalUploadCancelBtn"
                  onClick={() => {
                    setOpenModal(null);
                    setActiveBtnPicture(null);
                    setNewAvatar(null);
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NewStandardImages;
