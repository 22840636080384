import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import Synthetic from "../../../synthetic/Synthetic";
import AccountNavbar from "../../account_layout/AccountNavbar";
import AccountFooter from "../../account_layout/AccountFooter";
import styles from "./authRoute.module.css";

export default function AuthRoute() {
  if (!Cookies.get("refreshToken")) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className={styles.mainPage}>
      <Synthetic />
      <AccountNavbar />
      <Outlet />
      <AccountFooter />
    </div>
  );
}
