import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./login.module.css";
import { clearError, recovery } from "./authSlice";
import { selectError, selectUser, selectUserRegistr } from "./selectors";

function RecoveryEmail() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userRegistr = useSelector(selectUserRegistr);
  const [email, setEmail] = useState(user?.email || userRegistr?.email);
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => {
    dispatch(clearError());
    const emailValue = e.target.value;
    setEmail(emailValue);
  };
  const handleClick = async () => {
    const actionResult = await dispatch(
      recovery({
        email,
      })
    );
    if (recovery.fulfilled.match(actionResult)) {
      setMessage("Check your mail!");
    } else {
      setMessage("User with such email not found");
    }
  };
  useEffect(() => {}, [message]);

  return (
    <div className={styles.mainBody}>
      <div className={styles.mainContainer}>
        <div className={styles.loginBlock}>
          <div className={styles.logoContainer}>
            <div className={styles.logoComponent}>
              <Link
                to="/"
                className={location.pathname === "/" ? styles.logoBoxHome : styles.logoBoxOther}
                id="homeLogoLink"
              >
                <img
                  className={styles.logo}
                  src={location.pathname === "/" ? `/Logo-home.webp` : `/Logo-other.webp`}
                  alt="synthetic-icon"
                  id="navbarLogo"
                />
                <span className={styles.textLogo}>synthetic</span>
              </Link>
            </div>
          </div>
          <div className={styles.loginContainer}>
            <div className={styles.emailContainer}>
              <p className={styles.email}>{t("emailHeading")} *</p>
              <input
                type="email"
                className={`${styles.emailInput} ${styles.emailExample}`}
                placeholder={t("emailExample")}
                value={email}
                onChange={handleEmailChange}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                required
                id="emailInput"
              />
            </div>
            <div>{message}</div>
            <button className={styles.buttonContinue} type="button" onClick={handleClick} id="loginContinueButton">
              <div className={styles.buttonContinueText}>
                <span>Send</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecoveryEmail;
