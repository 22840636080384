import { useTranslation } from "react-i18next";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { clearError, confirmEmail } from "./authSlice";
import { selectError, selectUser } from "./selectors";
import styles from "./confirmation.module.css";

function Confirmation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currUser = useSelector(selectUser);
  const error = useSelector(selectError);
  const [confirmationCode, setConfirmationCode] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleCodeChange = (index, e) => {
    dispatch(clearError());
    const newConfirmationCode = [...confirmationCode];
    if (e.target.value.match(/^\d*$/)) {
      newConfirmationCode[index] = e.target.value;
      setConfirmationCode(newConfirmationCode);
      if (newConfirmationCode[index] !== "" && index < 3) {
        inputRefs[index + 1].current.focus();
      }
      if (newConfirmationCode[index] === "" && index <= 3) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleConfirmation = async () => {
    const code = confirmationCode.join("");
    const dispatchResult = await dispatch(
      confirmEmail({
        email: currUser.email,
        code,
      })
    );
    if (confirmEmail.fulfilled.match(dispatchResult)) {
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
    setConfirmationCode("");
  };

  if (!currUser) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.mainBody}>
      <div className={styles.mainContainer}>
        <div className={styles.confirmationBlock}>
          <div className={styles.signUpContainer}>
            <div className={styles.logoContainer}>
              <div className={styles.logoComponent}>
                <Link
                  to="/"
                  className={location.pathname === "/" ? styles.logoBoxHome : styles.logoBoxOther}
                  id="homeLogoLink"
                >
                  <img
                    className={styles.logo}
                    src={location.pathname === "/" ? `/Logo-home.webp` : `/Logo-other.webp`}
                    alt="synthetic-icon"
                    id="navbarLogo"
                  />
                  <span className={styles.textLogo}>synthetic</span>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.loginHeaderContainer}>
            <h1>Confirm your email</h1>
            <p className={styles.loginContainer}>
              {t("alreadyMember")}{" "}
              <Link to="/login" className={styles.linkLogin} id="loginLink">
                {t("logIn")}
              </Link>
            </p>
          </div>
          {currUser.state === "CONFIRMED" ? (
            <div className={styles.loginContainer}>
              <div className={styles.confirmMessageBox}>
                <CheckCircleIcon className={styles.confirmIcon} />
                <h2>{t("confirmationMessage")}</h2>
              </div>
              <p>{t("redirectMessage")}</p>
            </div>
          ) : (
            <div className={styles.loginContainer}>
              <div className={styles.inputContainer}>
                <p className={styles.text}>{t("sendCode")}</p>
                <div className={styles.inputCodeWrapper}>
                  {" "}
                  <input
                    type="text"
                    inputMode="numeric"
                    id="confirmationCode1"
                    ref={inputRefs[0]}
                    name="confirmationCode1"
                    maxLength="1"
                    pattern="\d*"
                    required
                    value={confirmationCode[0]}
                    onChange={(e) => handleCodeChange(0, e)}
                    className={styles.confirmationCode}
                  />
                  <input
                    type="text"
                    inputMode="numeric"
                    id="confirmationCode2"
                    ref={inputRefs[1]}
                    name="confirmationCode2"
                    maxLength="1"
                    pattern="\d*"
                    required
                    value={confirmationCode[1]}
                    onChange={(e) => handleCodeChange(1, e)}
                    className={styles.confirmationCode}
                  />
                  <input
                    type="text"
                    inputMode="numeric"
                    id="confirmationCode3"
                    ref={inputRefs[2]}
                    name="confirmationCode3"
                    maxLength="1"
                    pattern="\d*"
                    required
                    value={confirmationCode[2]}
                    onChange={(e) => handleCodeChange(2, e)}
                    className={styles.confirmationCode}
                  />
                  <input
                    type="text"
                    inputMode="numeric"
                    id="confirmationCode4"
                    ref={inputRefs[3]}
                    name="confirmationCode4"
                    maxLength="1"
                    pattern="\d*"
                    required
                    value={confirmationCode[3]}
                    onChange={(e) => handleCodeChange(3, e)}
                    className={styles.confirmationCode}
                  />
                </div>
                {error && (
                  <p className={styles.lineCheckValidPass}>
                    <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                    <span className={styles.errorMessage}>{t("errorMessageConfirm")}</span>
                  </p>
                )}
                <button className={styles.buttonContinue} type="button" onClick={handleConfirmation} id="confirmButton">
                  <div className={styles.buttonContinueText}>
                    <span>{t("loginButtonContinue")}</span>
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
