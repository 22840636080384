// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./customizeSettings.module.css";
import Button from "../../../../../../components/button/Button";
import buttonStyles from "../../../../../../components/button/button.module.css";

export default function CustomizeSettings({
  alignment,
  handleInputPosition,
  errorPositionMes,
  handleSaveAlignment,
  setCustomMode,
}) {
  const { t } = useTranslation();

  const botStyle = {
    position: "absolute",
    left: alignment.left !== "none" ? `${alignment.left}%` : "auto",
    right: alignment.right !== "none" ? `${alignment.right}%` : "auto",
    bottom: `${alignment.bottom}%`,
    width: "45px",
    height: "45px",
    backgroundColor: "#EC4A0A",
    borderRadius: "50%",
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContentCustomize}>
        <h1>Customize alignment</h1>
        <div className={styles.positionTotalWindow}>
          <div className={styles.windowInputPosition}>
            <span className={styles.nameText}>
              {alignment.active === "right" ? "Right margin (%)" : "Left margin (%)"}
            </span>
            <input
              className={styles.posInput}
              type="text"
              value={alignment.active === "right" ? alignment.right : alignment.left}
              name={alignment.active === "right" ? "right" : "left"}
              onChange={handleInputPosition}
            />
          </div>
          <div className={styles.windowInputPosition}>
            <span className={styles.nameText}>Bottom margin (%)</span>
            <input
              className={styles.posInput}
              type="text"
              name="bottom"
              value={alignment.bottom}
              onChange={handleInputPosition}
            />
          </div>
        </div>
        <p className={styles.errorText}>{errorPositionMes}</p>

        <div className={styles.pagePreview}>
          <div style={botStyle} />
        </div>

        <div className={styles.buttonLineElement}>
          <Button
            buttonName={t("saveBtn")}
            buttonType="submit"
            onClick={handleSaveAlignment}
            className={`${buttonStyles.buttonSave}`}
          />
          <Button
            buttonName={t("cancelBtn")}
            buttonType="button"
            onClick={() => setCustomMode(false)}
            className={buttonStyles.buttonCancel}
          />
        </div>
      </div>
    </div>
  );
}

CustomizeSettings.propTypes = {
  alignment: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    checked: PropTypes.bool,
    active: PropTypes.string,
  }).isRequired,
  handleInputPosition: PropTypes.func.isRequired,
  errorPositionMes: PropTypes.string.isRequired,
  handleSaveAlignment: PropTypes.func.isRequired,
  setCustomMode: PropTypes.func.isRequired,
};
