// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTranslation } from "react-i18next";
import styles from "../../createSynthetic.module.css";
import buttonStyles from "../../../../../components/button/button.module.css";
import PulsarSettings from "./sub_section/PulsarSettings";
import ButtonSettings from "./sub_section/ButtonSettings";
import Button from "../../../../../components/button/Button";
import { useDefaultSettings } from "../../../../../default_context/DefaultSettingsContext";

const AdvancedSettings = ({ selectedAvatar, onSaveSettings }) => {
  const { t } = useTranslation();
  const [showDropdownAdvancedSettings, setShowDropdownAdvancedSettings] = useState(false);
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
  const [isWarningActive, setIsWarningActive] = useState(false);
  const [isButtonSettingsEnabled, setIsButtonSettingsEnabled] = useState(false);
  const [isPulsarSettingsEnabled, setIsPulsarSettingsEnabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const {
    pulsarColor: defaultPulsarColor,
    pulsarSize: defaultPulsarSize,
    pulsarIntensive: defaultPulsarIntensive,
    buttonOptions: defaultButtonOptions,
  } = useDefaultSettings();

  const [pulsarColor, setPulsarColor] = useState(defaultPulsarColor);
  const [pulsarSize, setPulsarSize] = useState(defaultPulsarSize);
  const [pulsarIntensive, setPulsarIntensive] = useState(defaultPulsarIntensive);
  const [buttonOptions, setButtonOptions] = useState(defaultButtonOptions);

  const handleCancel = () => {
    setPulsarColor(defaultPulsarColor);
    setPulsarSize(defaultPulsarSize);
    setPulsarIntensive(defaultPulsarIntensive);
    setButtonOptions(defaultButtonOptions);
    setIsWarningActive(false);
    setIsSaveSuccessful(false);
    setIsSaveDisabled(false);
    setIsButtonSettingsEnabled(false);
    setIsPulsarSettingsEnabled(false);
  };

  const saveCustomSettings = () => {
    const settings = {
      pulsarColor,
      pulsarSize,
      pulsarIntensive,
      buttonOptions,
      isButtonSettingsEnabled,
      isPulsarSettingsEnabled,
    };

    onSaveSettings(settings);
    setIsSaveSuccessful(true);
    setIsWarningActive(false);
    setIsSaveDisabled(true);
  };

  useEffect(() => {
    const isPulsarChanged =
      pulsarColor !== defaultPulsarColor ||
      pulsarSize !== defaultPulsarSize ||
      pulsarIntensive !== defaultPulsarIntensive;

    const isButtonSettingsChanged = JSON.stringify(buttonOptions) !== JSON.stringify(defaultButtonOptions);

    const hasChanges =
      isPulsarChanged ||
      isButtonSettingsChanged ||
      isButtonSettingsEnabled !== false ||
      isPulsarSettingsEnabled !== false;

    setIsWarningActive(hasChanges);
    setIsSaveDisabled(!hasChanges);
  }, [
    pulsarColor,
    pulsarSize,
    pulsarIntensive,
    buttonOptions,
    isButtonSettingsEnabled,
    isPulsarSettingsEnabled,
    defaultPulsarColor,
    defaultPulsarSize,
    defaultPulsarIntensive,
    defaultButtonOptions,
  ]);

  const handlePulsarSettingsChange = (settings) => {
    setPulsarColor(settings.pulsarColor || defaultPulsarColor);
    setPulsarSize(settings.pulsarSize || defaultPulsarSize);
    setPulsarIntensive(settings.pulsarIntensive || defaultPulsarIntensive);
  };

  const handleButtonSettingsChange = (settings) => {
    setButtonOptions(settings || defaultButtonOptions);
  };

  return (
    <div>
      <button
        className={styles.btnDropdown}
        id="btnDropdownAdvancedSettings"
        type="button"
        onClick={() => setShowDropdownAdvancedSettings(!showDropdownAdvancedSettings)}
      >
        <span className={styles.textIconInButton}>
          {isSaveSuccessful ? (
            <DoneIcon style={{ color: "green" }} />
          ) : isWarningActive ? (
            <WarningAmberIcon style={{ color: "#EC4A0A" }} />
          ) : null}
          6. Advanced settings{" "}
        </span>
        {showDropdownAdvancedSettings ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      {showDropdownAdvancedSettings && (
        <div className={styles.advancedElement}>
          <PulsarSettings
            selectedAvatar={selectedAvatar}
            pulsarColor={pulsarColor}
            pulsarSize={pulsarSize}
            pulsarIntensive={pulsarIntensive}
            onPulsarSettingsChange={handlePulsarSettingsChange}
            onEnableChange={setIsPulsarSettingsEnabled}
            isEnabled={isPulsarSettingsEnabled}
          />
          <ButtonSettings
            buttonOptions={buttonOptions}
            onButtonSettingsChange={handleButtonSettingsChange}
            selectedAvatar={selectedAvatar}
            onEnableChange={setIsButtonSettingsEnabled}
            isEnabled={isButtonSettingsEnabled}
          />

          {(isButtonSettingsEnabled || isPulsarSettingsEnabled) && (
            <div className={styles.buttonLineElement}>
              <Button
                buttonName={t("saveBtn")}
                buttonType="submit"
                onClick={saveCustomSettings}
                className={`${buttonStyles.buttonSave}`}
                disabled={isSaveDisabled}
              />
              <Button
                buttonName={t("cancelBtn")}
                buttonType="button"
                onClick={handleCancel}
                className={buttonStyles.buttonCancel}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AdvancedSettings.propTypes = {
  selectedAvatar: PropTypes.string.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};

export default AdvancedSettings;
