import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "../../components/cookies/Cookies";
import styles from "./signUp.module.css";
import { clearErrorExistMessage, clearUserRigistr, register, setUserRegistr } from "./authSlice";
import Terms from "../../components/terms/Terms";
import Privacy from "../../components/privacy/Privacy";
import { selectErrorExistMessage, selectUserRegistr } from "./selectors";

function SignUp() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const userRegistr = useSelector(selectUserRegistr);
  const errorExistMessage = useSelector(selectErrorExistMessage);
  const [fullName, setFullName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordConfirmShow, setPasswordConfirmShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isFocusedPass, setFocusedPass] = useState(false);
  const [isFocusedConfirmPass, setFocusedConfirmPass] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContentKey, setModalContentKey] = useState("");

  const handleOpenModal = (contentKey) => {
    setModalContentKey(contentKey);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNameChange = (e) => {
    const nameValue = e.target.value;
    const isValidName = /^[a-zA-Z\u00C0-\u00FF\s'-]+$/.test(nameValue);
    setFullName(nameValue);
    setNameError(!isValidName);
  };

  const handleEmailChange = (e) => {
    dispatch(clearErrorExistMessage());
    dispatch(clearUserRigistr());
    const emailValue = e.target.value;
    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailValue);
    setEmail(emailValue);
    setEmailError(!isValidEmail);
  };

  const handlePhoneChange = (value) => {
    const isValidPhone = /^\+(?:[0-9] ?){6,14}[0-9]$/.test(value);
    setPhoneNumber(value);
    setPhoneError(!isValidPhone);
  };

  const handleInputPasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    const isValidPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(passwordValue);
    setPasswordError(!isValidPassword);
  };

  const handleInputConfirmChange = (e) => {
    const confirmValue = e.target.value;
    setConfirmPassword(confirmValue);
    const passwordMismatch = confirmValue !== password;
    setPasswordError(passwordMismatch);
  };

  const validatePassword = () => {
    // Validation pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=!])(?=\S+$).{8,}$/;

    // Validate lowercase letters
    const hasLowerCaseLetters = /[a-z]/gu.test(password);

    // Validate capital letters
    const hasUpperCaseLetters = /[A-Z]/gu.test(password);

    // Validate numbers
    const hasNumbers = /[0-9]/g.test(password);

    // Validate special characters
    const hasSpecialCharacters = /[@#$%^&+=!]/g.test(password);

    // Validate length
    const hasMinimumLength = password.length >= 8;

    return {
      hasLowerCaseLetters,
      hasUpperCaseLetters,
      hasNumbers,
      hasSpecialCharacters,
      hasMinimumLength,
    };
  };

  const { hasLowerCaseLetters, hasUpperCaseLetters, hasNumbers, hasSpecialCharacters, hasMinimumLength } =
    validatePassword();

  const handleFocusConfirmPass = () => {
    setFocusedConfirmPass(true);
  };

  const handleBlurConfirmPass = () => {
    setFocusedConfirmPass(false);
  };

  const handleConfirmPassword = () => {
    if (password.length === 0) {
      return true;
    }
    return confirmPassword !== password;
  };

  const passwordMismatch = handleConfirmPassword();

  const togglePasswordVisibility = () => {
    setPasswordShow(!passwordShow);
  };

  const togglePasswordConfirmVisibility = () => {
    setPasswordConfirmShow(!passwordConfirmShow);
  };

  const checkFullCorrect = () => {
    return (
      fullName.length > 1 &&
      !nameError &&
      email.length > 6 &&
      !emailError &&
      phoneNumber &&
      phoneNumber.length > 9 &&
      !phoneError &&
      hasLowerCaseLetters &&
      hasUpperCaseLetters &&
      hasNumbers &&
      hasSpecialCharacters &&
      hasMinimumLength &&
      !passwordMismatch
    );
  };

  const isFullCorrect = checkFullCorrect();
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const handleClick = async () => {
    setAttemptedSubmit(true);

    const resetForm = () => {
      setFullName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setAttemptedSubmit(false);
    };

    if (isFullCorrect) {
      const dispatchResult = await dispatch(
        register({
          fullName,
          email,
          phoneNumber,
          password,
        })
      );

      if (register.fulfilled.match(dispatchResult)) {
        await dispatch(
          setUserRegistr({
            fullName,
            email,
            phoneNumber,
          })
        );
        if (!dispatchResult.payload.message) {
          navigate("/confirm");
          resetForm();
        }
      }
    }
  };

  useEffect(() => {
    if (errorExistMessage) {
      setFullName(userRegistr.fullName);
      setEmail(userRegistr.email);
      setPhoneNumber(userRegistr.phoneNumber);
    }
  }, [dispatch, errorExistMessage, userRegistr]);

  return (
    <div className={styles.mainBody}>
      {isModalOpen && (
        <div className={styles.modalOverlay} onClick={handleCloseModal} role="button" tabIndex="0">
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()} role="button" tabIndex="0">
            <button type="button" className={styles.closeButton} onClick={handleCloseModal}>
              ×
            </button>
            <div className={styles.modalBody}>
              {modalContentKey === "terms" && <Terms />}
              {modalContentKey === "privacy" && <Privacy />}
            </div>
          </div>
        </div>
      )}
      <div className={styles.mainContainer}>
        <div className={styles.signUpBlock}>
          <div className={styles.signUpContainer}>
            <div className={styles.logoContainer}>
              <div className={styles.logoComponent}>
                <Link
                  to="/"
                  className={location.pathname === "/" ? styles.logoBoxHome : styles.logoBoxOther}
                  id="homeLogoLink"
                >
                  <img
                    className={styles.logo}
                    src={location.pathname === "/" ? `/Logo-home.webp` : `/Logo-other.webp`}
                    alt="synthetic-icon"
                    id="navbarLogo"
                  />
                  <span className={styles.textLogo}>synthetic</span>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.signUpBox}>
            <div className={styles.loginHeaderContainer}>
              <h1>Sign up</h1>
              <p className={styles.loginContainer}>
                {t("alreadyMember")}{" "}
                <Link to="/login" className={styles.linkLogin} id="loginLink">
                  {t("logIn")}
                </Link>
              </p>
            </div>

            <div className={styles.inputContainer}>
              <p className={styles.name}>{t("nameHeading")} *</p>
              <input
                type="text"
                className={`${styles.nameInput} ${styles.nameExample} ${(attemptedSubmit && !fullName) || nameError ? styles.errorInput : ""}`}
                placeholder={t("nameExample")}
                value={fullName}
                onChange={handleNameChange}
                required
                pattern="^[a-zA-Z\u00C0-\u00FF\s'-]+$"
                title={t("fullNameRequirements")}
                id="signUpNameInput"
              />

              {nameError && (
                <p className={styles.lineCheckValidPass}>
                  <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                  <span className={styles.errorMessage}>{t("nameErrorDescription")}</span>
                </p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <p className={styles.email}>{t("emailHeading")} *</p>
              <input
                type="email"
                className={`${styles.emailInput} ${styles.emailExample} ${(attemptedSubmit && !email) || emailError ? styles.errorInput : ""} ${errorExistMessage.message ? styles.errorInput : ""}`}
                placeholder={t("emailExample")}
                value={email}
                onChange={handleEmailChange}
                required
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                title={t("emailRequirements")}
                id="signUpEmailInput"
              />
              {emailError && (
                <p className={styles.lineCheckValidPass}>
                  <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                  <span className={styles.errorMessage}>{t("emailErrorDescription")}</span>
                </p>
              )}
              {errorExistMessage && (
                <p className={styles.lineCheckValidPass}>
                  <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                  <span className={styles.errorMessage}>{errorExistMessage}</span>
                </p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <p className={styles.phone}>{t("phoneHeading")} *</p>
              <PhoneInput
                type="phone"
                className={`${styles.phoneInput} ${styles.phoneExample} ${(attemptedSubmit && !phoneNumber) || phoneError ? styles.errorInput : ""}`}
                placeholder={t("phoneExample")}
                value={phoneNumber}
                onChange={handlePhoneChange}
                defaultCountry={navigator.language.split("-")[1]?.toUpperCase() || "US"}
                international
                pattern="^\+(?:[0-9] ?){6,14}[0-9]$"
                required
                title={t("phoneRequirements")}
                id="signUpPhoneInput"
              />
            </div>
            <div className={styles.passwordContainer}>
              <p className={styles.password}>{t("passwordHeading")} *</p>
              <div className={styles.passwordInputContainer}>
                <input
                  type={passwordShow ? "text" : "password"}
                  className={`${styles.passwordInput} ${styles.passwordExample} ${(attemptedSubmit && !password) || passwordError ? styles.errorInput : ""}`}
                  placeholder={t("passwordExample")}
                  value={password}
                  onFocus={() => setFocusedPass(true)}
                  onBlur={() => setFocusedPass(false)}
                  onChange={handleInputPasswordChange}
                  pattern="^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@#$%^&+=!])(?=\\S+$).{8,}$"
                  title={t("passwordRequirements")}
                  required
                  id="signUpPasswordInput"
                />
                {passwordShow ? (
                  <VisibilityIcon onClick={togglePasswordVisibility} className={styles.eyeIcon} />
                ) : (
                  <VisibilityOffIcon onClick={togglePasswordVisibility} className={styles.eyeIcon} />
                )}
              </div>
              {isFocusedPass && !hasLowerCaseLetters && (
                <p className={styles.lineCheckValidPass}>
                  <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                  <span className={styles.errorMessage}>{t("passwordRequirementLC")}</span>
                </p>
              )}
              {isFocusedPass && !hasUpperCaseLetters && (
                <p className={styles.lineCheckValidPass}>
                  <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                  <span className={styles.errorMessage}>{t("passwordRequirementUC")}</span>
                </p>
              )}
              {isFocusedPass && !hasNumbers && (
                <p className={styles.lineCheckValidPass}>
                  <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                  <span className={styles.errorMessage}>{t("passwordRequirementNum")}</span>
                </p>
              )}
              {isFocusedPass && !hasSpecialCharacters && (
                <p className={styles.lineCheckValidPass}>
                  <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                  <span className={styles.errorMessage}>{t("passwordRequirementSym")}</span>
                </p>
              )}
              {isFocusedPass && !hasMinimumLength && (
                <p className={styles.lineCheckValidPass}>
                  <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                  <span className={styles.errorMessage}>{t("passwordRequirementMin")}</span>
                </p>
              )}
            </div>
            <div className={styles.passwordContainer}>
              <p className={styles.password}>{t("confirmPasswordHeading")} *</p>
              <div className={styles.passwordInputContainer}>
                <input
                  type={passwordConfirmShow ? "text" : "password"}
                  className={`${styles.passwordInput} ${styles.passwordExample} ${(attemptedSubmit && !confirmPassword) || confirmPassword !== password ? styles.errorInput : ""}`}
                  placeholder={t("passwordConfirmExample")}
                  value={confirmPassword}
                  onFocus={handleFocusConfirmPass}
                  onBlur={handleBlurConfirmPass}
                  onChange={handleInputConfirmChange}
                  required
                  title={t("passwordRequirements")}
                  id="signUpConfirmPasswordInput"
                />
                {passwordConfirmShow ? (
                  <VisibilityIcon onClick={togglePasswordConfirmVisibility} className={styles.eyeIcon} />
                ) : (
                  <VisibilityOffIcon onClick={togglePasswordConfirmVisibility} className={styles.eyeIcon} />
                )}
              </div>
              {isFocusedConfirmPass && confirmPassword.length > 0 && passwordMismatch && (
                <div>
                  <p className={styles.lineCheckValidPass}>
                    <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                    <span className={styles.invalid}>{t("passwordMismatch")}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <button
            className={`${styles.buttonContinue} ${isFullCorrect ? "" : styles.buttonNoFullCorrect}`}
            type="button"
            onClick={handleClick}
            id="signUpContinueButton"
          >
            <div className={styles.buttonContinueText}>
              <span>{t("loginButtonContinue")}</span>
            </div>
          </button>
          <div className={styles.agreementTextContainer}>
            <div className={styles.agreementText}>
              {t("agreementText1")}
              <button type="button" onClick={() => handleOpenModal("terms")} className={styles.link} id="termsLink">
                {t("terms-and-conditions")}
              </button>
              {t("agreementText2")}
              <button
                type="button"
                onClick={() => handleOpenModal("privacy")}
                className={styles.link}
                id="privacyPolicyLink"
              >
                {t("privacy-policy")}
              </button>
              {i18n.language === "de" && t("agreementText3")}
            </div>
          </div>
        </div>
      </div>
      <Cookies />
    </div>
  );
}

export default SignUp;
