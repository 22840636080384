import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import styles from "./accountSynthetic.module.css";
import { selectAllSynthetic, selectIsDeleting } from "./selectors";
import { loadAllSynthetic } from "./userSyntheticSlice";
import PreLoading from "../../components/pre_loading/PreLoading";

export default function AccountSynthetic() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const synthetics = useSelector(selectAllSynthetic);
  const isDeleting = useSelector(selectIsDeleting);
  const [isMobile, setIsMobile] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [activeButton, setActiveButton] = useState("button1");
  const [videoRun, setVideoRun] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const instructionVideo = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video/Instruction.mp4";
  const handleCreateSynthetic = () => {
    navigate("/account/mySynthetic/create");
  };

  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleOnClick = () => {
    setVideoRun(true);
    setAutoPlay(true);
  };

  const handleVideoEnded = () => {
    setAutoPlay(false);
    setVideoRun(false);
  };

  const handleClick = (path, buttonId) => {
    navigate(path);
    setActiveButton(buttonId);
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const handleViewAllDetails = (synId) => {
    navigate(`/account/mySynthetic/${synId}`);
  };

  useEffect(() => {
    const updateIsMobile = () => {
      const maxWidth = Math.max(window.innerWidth, window.innerHeight);
      setIsMobile(maxWidth < 1024);
    };
    updateIsMobile();
    window.addEventListener("resize", updateIsMobile);
    dispatch(loadAllSynthetic());
    return () => window.removeEventListener("resize", updateIsMobile);
  }, [dispatch]);

  if (isMobile) {
    return (
      <div className={styles.mobileWarningBody}>
        <div className={styles.mobileWarning}>
          <p className={styles.mobileMessage}>{t("mobileWarning")}</p>
          <button className={styles.buttonWarning} onClick={handleGoHome} type="button" id="warningButton">
            <div className={styles.buttonWarningText}>
              <span>{t("buttonBackMobile")}</span>
            </div>
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.mainBox}>
      <div className={styles.leftBarContainer}>
        <div>
          <button
            className={`${styles.button1} ${activeButton === "button1" || hoveredButton === "button1" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/mySynthetic", "button1")}
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>My Synthetic</span>
              </div>
              {activeButton === "button1" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button1" />
                </div>
              )}
            </div>
          </button>
          <button
            className={`${styles.button2} ${activeButton === "button2" || hoveredButton === "button2" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/payment/pricing", "button2")}
            onMouseEnter={() => handleMouseEnter("button2")}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Payment</span>
              </div>
              {hoveredButton === "button2" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button2" />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
      {isDeleting ? (
        <PreLoading />
      ) : (
        <div className={styles.syntheticsBox}>
          {synthetics.map((syn) => {
            if (!syn.hide) {
              return (
                <div key={syn.id} className={styles.syntheticCard}>
                  <button
                    id={syn.id}
                    type="button"
                    title="view all details about synthetic"
                    className={styles.syntheticButton}
                    onClick={() => handleViewAllDetails(syn.id)}
                  >
                    <img src={`data:image/*;base64,${syn.avatar}`} alt={syn.name} />
                  </button>
                  <p className={styles.syntheticName} htmlFor={syn.id}>
                    {syn.name}
                  </p>
                </div>
              );
            }
            return <div />;
          })}
          <div className={styles.syntheticCard}>
            <button
              id="createBtn"
              type="button"
              title="create new synthetic"
              className={styles.createSynthetic}
              onClick={handleCreateSynthetic}
            >
              +
            </button>
            <p htmlFor="createBtn">Create new Synthetic</p>
          </div>
        </div>
      )}

      <div className={styles.instructionBox}>
        <h3 className={styles.headerInstruction}>
          <HelpIcon />
          <span>Video introduction</span>
        </h3>
        {videoRun ? (
          <div className={styles.videoInstructionBox}>
            <video
              controls
              className={styles.videoInstruction}
              src={instructionVideo}
              preload="metadata"
              playsInline
              type="video/mp4"
              autoPlay={autoPlay}
              onEnded={handleVideoEnded}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (
          <button type="button" className={styles.videoInstructionBox} onClick={handleOnClick}>
            <img className={styles.videoInstruction} src="/tempVideo.jpg" />
          </button>
        )}
      </div>
    </div>
  );
}
