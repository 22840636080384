// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./pulsarSettings.module.css";

const PulsarSettings = ({
  selectedAvatar,
  pulsarColor,
  pulsarSize,
  pulsarIntensive,
  onPulsarSettingsChange,
  onEnableChange,
  isEnabled,
}) => {
  const [pulsarEnabled, setPulsarEnabled] = useState(isEnabled);

  const handleColorChange = (e) => {
    onPulsarSettingsChange({
      pulsarColor: e.target.value,
      pulsarSize,
      pulsarIntensive,
    });
  };

  const handleSizeChange = (e) => {
    onPulsarSettingsChange({
      pulsarColor,
      pulsarSize: parseInt(e.target.value, 10),
      pulsarIntensive,
    });
  };

  const handleIntensiveChange = (e) => {
    onPulsarSettingsChange({
      pulsarColor,
      pulsarSize,
      pulsarIntensive: parseInt(e.target.value, 10),
    });
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 ${pulsarColor};
        }
        50% {
          box-shadow: 0 0 0 ${pulsarSize}px rgba(0, 123, 255, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [pulsarColor, pulsarSize]);

  useEffect(() => {
    setPulsarEnabled(isEnabled);
  }, [isEnabled]);

  useEffect(() => {
    onEnableChange(pulsarEnabled);
  }, [onEnableChange, pulsarEnabled]);

  return (
    <div className={styles.pulsarSettingsContainer}>
      <div className={styles.leftSettings}>
        <h3>
          <u>Pulsar options</u>
        </h3>

        <div className={styles.settingsRow}>
          <label className={styles.labelWithInput}>
            <input type="checkbox" checked={pulsarEnabled} onChange={() => setPulsarEnabled(!pulsarEnabled)} />
            Enable
          </label>
        </div>

        {pulsarEnabled && (
          <>
            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Color:
                <input type="color" value={pulsarColor} onChange={handleColorChange} />
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Pulsar Size (px):
                <input type="number" value={pulsarSize} onChange={handleSizeChange} />
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Pulsar Intensive (sec):
                <input type="number" value={pulsarIntensive} onChange={handleIntensiveChange} />
              </label>
            </div>
          </>
        )}
      </div>

      {pulsarEnabled && (
        <div className={styles.rightAvatarSimulator}>
          <div
            className={styles.simulatorContainer}
            style={{
              animation: `pulse ${pulsarIntensive}s infinite`,
            }}
          >
            <img src={selectedAvatar} alt="Avatar" className={styles.simulatorAvatar} />
          </div>
        </div>
      )}
    </div>
  );
};

PulsarSettings.defaultProps = {
  isEnabled: false,
};

PulsarSettings.propTypes = {
  selectedAvatar: PropTypes.string.isRequired,
  pulsarColor: PropTypes.string.isRequired,
  onEnableChange: PropTypes.func.isRequired,
  pulsarSize: PropTypes.number.isRequired,
  pulsarIntensive: PropTypes.number.isRequired,
  onPulsarSettingsChange: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
};

export default PulsarSettings;
