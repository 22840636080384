import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./newPassword.module.css";
import { clearError, changePassword } from "./authSlice";
import { selectError, selectUser, selectUserRegistr } from "./selectors";


function NewPassword() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(selectUser);
  const userRegistr = useSelector(selectUserRegistr);
  const [email, setEmail] = useState(user?.email || userRegistr?.email);
  const [firstPassword, setFirstPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [firstPasswordShown, setFirstPasswordShown] = useState(false);
  const [secondPasswordShown, setSecondPasswordShown] = useState(false);
  const [message, setMessage] = useState("");


  const toggleFirstPasswordVisibility = () => {
    setFirstPasswordShown(!firstPasswordShown);
  };

  const toggleSecondPasswordVisibility = () => {
    setSecondPasswordShown(!secondPasswordShown);
  };

  const handleEmailChange = (e) => {
    dispatch(clearError());
    const emailValue = e.target.value;
    setEmail(emailValue);
  };
  const handleInputFirstPasswordChange = (e) => {
    dispatch(clearError());
    const passwordValue = e.target.value;
    setFirstPassword(passwordValue);
  };
  const handleInputSecondPasswordChange = (e) => {
    dispatch(clearError());
    const passwordValue = e.target.value;
    setSecondPassword(passwordValue);
  };

  const handleClick = async () => {
    if (firstPassword === secondPassword) {
      const actionResult = await dispatch(
        changePassword({
          email: email,
          password: firstPassword,
        })
      );
      if (changePassword.fulfilled.match(actionResult)) {
        navigate("/login", { replace: true });
      } else {
        setMessage("Password is not secure or email is wrong");
      }
    }
  };

  return (
    <div className={styles.mainBody}>
      <div className={styles.mainContainer}>
      <div className={styles.loginBlock}>
          <div className={styles.logoContainer}>
            <div className={styles.logoComponent}>
              <Link
                to="/"
                className={location.pathname === "/" ? styles.logoBoxHome : styles.logoBoxOther}
                id="homeLogoLink"
              >
                <img
                  className={styles.logo}
                  src={location.pathname === "/" ? `/Logo-home.webp` : `/Logo-other.webp`}
                  alt="synthetic-icon"
                  id="navbarLogo"
                />
                <span className={styles.textLogo}>synthetic</span>
              </Link>
            </div>
          </div>
          <div className={styles.loginContainer}>
            <div className={styles.emailContainer}>
              <p className={styles.email}>{t("emailHeading")} *</p>
              <input
                type="email"
                className={`${styles.emailInput} ${styles.emailExample}`}
                placeholder={t("emailExample")}
                value={email}
                onChange={handleEmailChange}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                required
                id="emailInput"
              />
            </div>
            <div className={styles.passwordContainer}>
              <p className={styles.password}>{t("passwordHeading")} *</p>
              <div className={styles.passwordInputContainer}>
                <input
                  type={firstPasswordShown ? "text" : "password"}
                  className={`${styles.passwordInput} ${styles.passwordExample}`}
                  placeholder={t("passwordExample")}
                  value={firstPassword}
                  onChange={handleInputFirstPasswordChange}
                  pattern="^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@#$%^&+=!])(?=\\S+$).{8,}$"
                  required
                  id="passwordInput"
                />
                {firstPasswordShown ? (
                  <VisibilityIcon onClick={toggleFirstPasswordVisibility} className={styles.eyeIcon} />
                ) : (
                  <VisibilityOffIcon onClick={toggleFirstPasswordVisibility} className={styles.eyeIcon} />
                )}
              </div>
              <p className={styles.password}>{"Confirm password"} *</p>
              <div className={styles.passwordInputContainer}>
                <input
                  type={secondPasswordShown ? "text" : "password"}
                  className={`${styles.passwordInput} ${styles.passwordExample}`}
                  placeholder={t("passwordExample")}
                  value={secondPassword}
                  onChange={handleInputSecondPasswordChange}
                  required
                  id="passwordInput"
                />
                {secondPasswordShown ? (
                  <VisibilityIcon onClick={toggleSecondPasswordVisibility} className={styles.eyeIcon} />
                ) : (
                  <VisibilityOffIcon onClick={toggleSecondPasswordVisibility} className={styles.eyeIcon} />
                )}
              </div>
            </div>
            <div>{message}</div>
            <button className={styles.buttonContinue} type="button" onClick={handleClick} id="loginContinueButton">
              <div className={styles.buttonContinueText}>
                <span>Send</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
