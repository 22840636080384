import { useNavigate } from "react-router-dom";
import { useState } from "react";
import styles from "./contactInfo.module.css";

export default function ContactInfo() {
  const navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = useState(null);
  const [activeButton, setActiveButton] = useState("button2");

  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleClick = (path, buttonId) => {
    navigate(path);
    setActiveButton(buttonId);
  };

  return (
    <div className={styles.mainBox}>
      <div className={styles.leftBarContainer}>
        <div className={styles.backContainer}>
          <img src="/circle.webp" alt="buttonBack" />
        </div>
        <div>
          <button
            className={`${styles.buttonBack} ${activeButton === "buttonBack" ? styles.buttonBackPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/mySynthetic", "buttonBack")}
            id="backToMyAccountButton"
          >
            <div className={styles.buttonBackContainer}>
              <img src="/arrow-right.webp" alt="arrow" className={styles.arrowIcon} />
              <div className={styles.buttonBackTextContainer}>
                <span>My account</span>
              </div>
            </div>
          </button>
        </div>
        <div>
          <button
            className={`${styles.button1} ${activeButton === "button1" || hoveredButton === "button1" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/profile/privateDate", "button1")}
            onMouseEnter={() => handleMouseEnter("button1")}
            onMouseLeave={handleMouseLeave}
            id="privateDataButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Private data</span>
              </div>
              {hoveredButton === "button1" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button1" />
                </div>
              )}
            </div>
          </button>
          <button
            className={`${styles.button2} ${activeButton === "button2" || hoveredButton === "button2" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/profile/contact", "button2")}
            onMouseEnter={() => handleMouseEnter("button2")}
            onMouseLeave={handleMouseLeave}
            id="contactInfoButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Contact info</span>
              </div>
              {activeButton === "button2" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button2" />
                </div>
              )}
            </div>
          </button>
          <button
            className={`${styles.button3} ${activeButton === "button3" || hoveredButton === "button3" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/profile/company", "button3")}
            onMouseEnter={() => handleMouseEnter("button3")}
            onMouseLeave={handleMouseLeave}
            id="companyInfoButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Company info</span>
              </div>
              {hoveredButton === "button3" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button3" />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
      <div className={styles.contactInfoCard}>
        <h3>Contact Info</h3>
      </div>
    </div>
  );
}
