import { axiosDefault } from "../auth/service/axios.config";

export async function addFeedback(feedback) {
  const res = await axiosDefault.post("/api/feedbacks/add", JSON.stringify(feedback));
  if (res.status !== 201) {
    throw res.data;
  }
  return res.data;
}

export async function getAllFeedback() {
  const res = await axiosDefault.get("/api/feedbacks");
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}
