import { useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import styles from "../../createSynthetic.module.css";
import CustomizeSettings from "./sub_sections/CustomizeSettings";

export default function AlignmentSettings({
  onAlignmentChange,
  defaultAlignment,
  checkDoneAlignment,
  setCheckDoneAlignment,
  showWarning,
}) {
  const [showDropdownAlignment, setShowDropdownAlignment] = useState(false);
  const [customMode, setCustomMode] = useState(false);
  const [errorPositionMes, setErrorPositionMes] = useState("");
  const [alignment, setAlignment] = useState(defaultAlignment);

  useEffect(() => {
    setAlignment((prevAlignment) => ({
      ...prevAlignment,
      left: prevAlignment.left === "none" ? defaultAlignment.left : prevAlignment.left,
      right: prevAlignment.right === "none" ? defaultAlignment.right : prevAlignment.right,
    }));
  }, [defaultAlignment]);

  const handleInputPosition = (event) => {
    const { name: key, value } = event.target;

    if (value === "" || /^\d+$/.test(value)) {
      setErrorPositionMes("");
      setAlignment((prevAlignment) => ({
        ...prevAlignment,
        [key]: value,
      }));
    } else {
      setErrorPositionMes("Please enter a valid non-negative number.");
    }
  };

  const handleSaveAlignment = () => {
    if (!errorPositionMes) {
      const updatedAlignment = {
        ...alignment,
        active: "custom",
        left: alignment.left || "3",
        right: alignment.right || "none",
        bottom: alignment.bottom || "5",
      };
      setAlignment(updatedAlignment);
      onAlignmentChange(updatedAlignment);
      setCustomMode(false);
      setCheckDoneAlignment(true);
    }
  };

  const handleAlignmentSelection = (alignmentType) => {
    const updatedAlignment = {
      left: alignmentType === "left" ? "3" : "none",
      right: alignmentType === "right" ? "3" : "none",
      bottom: "5",
      active: alignmentType,
      checked: true,
    };

    setAlignment(updatedAlignment);
    onAlignmentChange(updatedAlignment);
    setCheckDoneAlignment(true);
  };

  const renderIcon = () => {
    if (!checkDoneAlignment && showWarning) {
      return <WarningAmberIcon style={{ color: "#EC4A0A" }} />;
    }
    if (checkDoneAlignment) {
      return <DoneIcon style={{ color: "green" }} />;
    }
    return null;
  };

  return (
    <div>
      <button
        className={styles.btnDropdown}
        id="btnDropdownAligment"
        type="button"
        onClick={() => setShowDropdownAlignment(!showDropdownAlignment)}
      >
        <span className={styles.textIconInButton}>
          {renderIcon()}
          2. Select synthetic alignment on page
        </span>
        {showDropdownAlignment ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      {showDropdownAlignment && (
        <div className={`${styles.dropdownElement} ${styles.aligment}`}>
          <button
            className={`${styles.btn} ${alignment.active === "left" ? styles.activeBtnBlue : ""}`}
            id="btnAligmentLeft"
            type="button"
            onClick={() => handleAlignmentSelection("left")}
          >
            Left
          </button>
          <button
            className={`${styles.btn} ${alignment.active === "right" ? styles.activeBtnBlue : ""}`}
            id="btnAligmentRight"
            type="button"
            onClick={() => handleAlignmentSelection("right")}
          >
            Right
          </button>
          {alignment.checked && (
            <button
              className={`${styles.btn} ${alignment.active === "custom" ? styles.activeBtnBlue : ""}`}
              id="btnCustomAlignment"
              type="button"
              onClick={() => setCustomMode(!customMode)}
            >
              Customize
            </button>
          )}
          {customMode && (
            <CustomizeSettings
              alignment={alignment}
              handleInputPosition={handleInputPosition}
              errorPositionMes={errorPositionMes}
              handleSaveAlignment={handleSaveAlignment}
              setCustomMode={setCustomMode}
            />
          )}
        </div>
      )}
    </div>
  );
}

AlignmentSettings.propTypes = {
  onAlignmentChange: PropTypes.func.isRequired,
  defaultAlignment: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    checked: PropTypes.bool,
    active: PropTypes.string,
  }),
  checkDoneAlignment: PropTypes.bool,
  setCheckDoneAlignment: PropTypes.func.isRequired,
  showWarning: PropTypes.bool,
};

AlignmentSettings.defaultProps = {
  defaultAlignment: {
    left: "3",
    right: "none",
    bottom: "5",
    checked: false,
    active: "",
  },
  checkDoneAlignment: false,
  showWarning: false,
};
