import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./contactUs.module.css";
import { addFeedback } from "./api";

function ContactUs() {
  const { t } = useTranslation();
  const [nameError, setNameError] = useState(false);
  const [name, setName] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  const handleNameChange = (e) => {
    const nameValue = e.target.value.trim();
    const isValidName = /^[a-zA-Z\u00C0-\u00FF\s'-]+$/.test(nameValue);
    setName(nameValue);
    setNameError(!isValidName);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.trim();
    const isValidEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(emailValue);
    setEmail(emailValue);
    setEmailError(!isValidEmail);
  };

  const checkFullCorrect = () => {
    return name && !nameError && email && !emailError && message;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSending(true);

    const feedback = {
      name,
      email,
      message,
    };

    const res = await addFeedback(feedback);
    if (res.email === email) {
      setIsSending(false);
      setIsSubmittedSuccessfully(true);
    } else {
      setIsSending(false);
      setIsSubmittedSuccessfully(false);
    }
  };

  const handleBackBtn = () => {
    // Reset form after successful submission
    setIsSubmittedSuccessfully(false);
    setErrorMessage("");
    setIsSending(false);
    setName("");
    setEmail("");
    setMessage("");
    setErrorMessage("");
  };

  const maxLength = 500;

  return (
    <div className={styles.mainBody}>
      <div id="contactUs" className={styles.mainContainer}>
        <div className={styles.contactUsContainer}>
          <div className={styles.formContainer}>
            <div className={styles.contactUsTitleContainer}>
              <h1 className={styles.contactUsTitle}>{t("contactUs")}</h1>
            </div>
            <form onSubmit={handleSubmit} className={styles.contactForm}>
              {!isSubmittedSuccessfully && !isSending && !errorMessage ? (
                <>
                  <div className={styles.formGroup}>
                    <label htmlFor="name">{t("nameLabel")} *</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder={t("namePlaceholder")}
                      value={name}
                      onChange={handleNameChange}
                      required
                      pattern="^[a-zA-Z\u00C0-\u00FF\s'-]+$"
                    />
                    {nameError && (
                      <p className={styles.lineCheckValidPass}>
                        <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                        <span className={styles.errorMessage}>{t("nameErrorDescription")}</span>
                      </p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="email">{t("emailLabel")} *</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder={t("emailPlaceholder")}
                      value={email}
                      onChange={handleEmailChange}
                      required
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                    />
                    {emailError && (
                      <p className={styles.lineCheckValidPass}>
                        <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                        <span className={styles.errorMessage}>{t("emailErrorDescription")}</span>
                      </p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="message">{t("messageLabel")} *</label>
                    <textarea
                      id="message"
                      name="message"
                      placeholder={t("messagePlaceholder")}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                      maxLength={maxLength}
                    />
                    <div
                      className={styles.characterCount}
                    >{`${message.length}/${maxLength} ${t("charactersUsed")}`}</div>
                  </div>
                  <div className={styles.submitButtonContainer}>
                    <button
                      type={checkFullCorrect() ? "submit" : "button"}
                      className={`${styles.submitButton} ${checkFullCorrect() ? "" : styles.noActive}`}
                      disabled={!checkFullCorrect()}
                    >
                      <div className={styles.buttonSendText}>
                        <span>{t("send")}</span>
                      </div>
                    </button>
                  </div>
                </>
              ) : (
                <div className={styles.successfulForm}>
                  {isSending ? (
                    <p className={styles.sendingMessage}>{t("sendingMessage")}</p>
                  ) : isSubmittedSuccessfully ? (
                    <>
                      <h1 className={styles.titlePage}>{t("Successful")}</h1>
                      <p className={styles.successfulFormText}>{t("FeedbackRequestConfirmation")}</p>
                      <button type="button" className={styles.backButton} onClick={handleBackBtn}>
                        {t("back").toUpperCase()}
                      </button>
                    </>
                  ) : (
                    <>
                      <p className={styles.errorMessageBack}>{t("errorMessageBack")}</p>
                      <button type="button" className={styles.backButtonTryAgain} onClick={handleBackBtn}>
                        {t("tryAgain").toUpperCase()}
                      </button>
                    </>
                  )}
                </div>
              )}
            </form>
          </div>
          <div className={styles.infoContainer}>
            <h2 className={styles.title}>{t("contactUsToday")}</h2>
            <p className={styles.text}>{t("telethon")}</p>
            <p className={styles.textAddress}>{t("addressPar1")}</p>
            <p className={styles.textAddress}>{t("addressPar2")}</p>
            <div className={styles.mapContainer}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1980.1268540798399!2d24.90355321307276!3d60.24482007496662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468df7d3138f8893%3A0xf9f256ff154b912f!2sVuorilinnakkeenpolku%204%2C%2000430%20Helsinki%2C%20Finland!5e0!3m2!1sen!2sde!4v1709120331668!5m2!1sen!2sde"
                width="550"
                height="340"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="00430 Helsinki, Finland"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
