import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import styles from "./accountFooter.module.css";

export default function AccountFooter() {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={styles.footerBox}>
      <div className={styles.contextFooterBox}>
        <p className={styles.sepLine} />
        <div className={styles.bottomInfoBox}>
          <span>© 2024 Synthetic Inc.</span>
          <Link to="/terms" id="termsLink">
            {t("termsAndConditions")}
          </Link>
          <Link to="/privacy" id="privacyLink">
            {t("privacyPolicy")}
          </Link>
          <div className={styles.bottomInfoBoxButtons}>
            <button type="button" id="facebookButton">
              <img src="/facebook.webp" alt="facebook" />
            </button>
            <button type="button" id="instagramButton">
              <img src="/instagram.webp" alt="instagram" />
            </button>
            <button type="button" id="twitterButton">
              <img src="/twitter.webp" alt="X" />
            </button>
            <button type="button" id="linkedinButton">
              <img src="/linkedin.webp" alt="linkedin" />
            </button>
            <button type="button" id="tiktokButton">
              <img src="/tiktok.webp" alt="tik-tok" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
