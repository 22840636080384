import { combineReducers, configureStore } from "@reduxjs/toolkit";
import syntheticReducer from "../synthetic/syntheticSlice";
import syntheticParamsReducer from "../synthetic/syntheticParamsSlice";
import authReducer from "../features/auth/authSlice";
import subscriptionReducer from "../features/subscription/subscriptionSlice";
import userSyntheticReducer from "../features/user_synthetic/userSyntheticSlice";
import languageReducer from "../features/language_selector/languageSlice";

const rootReducer = combineReducers({
  synthetic: syntheticReducer,
  syntheticParams: syntheticParamsReducer,
  auth: authReducer,
  userSynthetic: userSyntheticReducer,
  language: languageReducer,
  subscription: subscriptionReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
