import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "../../components/cookies/Cookies";
import styles from "./login.module.css";
import { clearError, login } from "./authSlice";
import Terms from "../../components/terms/Terms";
import Privacy from "../../components/privacy/Privacy";
import { selectError, selectUser, selectUserRegistr } from "./selectors";

function Login() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(selectUser);
  const userRegistr = useSelector(selectUserRegistr);
  const error = useSelector(selectError);
  const [email, setEmail] = useState(user?.email || userRegistr?.email);
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContentKey, setModalContentKey] = useState("");

  const handleOpenModal = (contentKey) => {
    setModalContentKey(contentKey);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEmailChange = (e) => {
    dispatch(clearError());
    const emailValue = e.target.value;
    setEmail(emailValue);
  };

  const handleInputPasswordChange = (e) => {
    dispatch(clearError());
    const passwordValue = e.target.value;
    setPassword(passwordValue);
  };
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const handleClick = async () => {
    const actionResult = await dispatch(
      login({
        login: email,
        password,
      })
    );

    if (login.fulfilled.match(actionResult)) {
      navigate("/account/mySynthetic", { replace: true });
      setEmail("");
      setPassword("");
    }
  };

  return (
    <div className={styles.mainBody}>
      {isModalOpen && (
        <div className={styles.modalOverlay} onClick={handleCloseModal} role="button" tabIndex="0">
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()} role="button" tabIndex="0">
            <button type="button" className={styles.closeButton} onClick={handleCloseModal}>
              ×
            </button>
            <div className={styles.modalBody}>
              {modalContentKey === "terms" && <Terms />}
              {modalContentKey === "privacy" && <Privacy />}
            </div>
          </div>
        </div>
      )}
      <div className={styles.mainContainer}>
        <div className={styles.loginBlock}>
          <div className={styles.logoContainer}>
            <div className={styles.logoComponent}>
              <Link
                to="/"
                className={location.pathname === "/" ? styles.logoBoxHome : styles.logoBoxOther}
                id="homeLogoLink"
              >
                <img
                  className={styles.logo}
                  src={location.pathname === "/" ? `/Logo-home.webp` : `/Logo-other.webp`}
                  alt="synthetic-icon"
                  id="navbarLogo"
                />
                <span className={styles.textLogo}>synthetic</span>
              </Link>
            </div>
          </div>
          <div className={styles.loginContainer}>
            <div className={styles.loginHeaderContainer}>
              <h1>Login</h1>
              <p className={styles.signUpContainer}>
                {t("notMember")}{" "}
                <Link to="/signUp" className={styles.linkSignUp} id="signUpLink">
                  {t("signUp")}
                </Link>
              </p>
            </div>
            <div className={styles.emailContainer}>
              <p className={styles.email}>{t("emailHeading")} *</p>
              <input
                type="email"
                className={`${styles.emailInput} ${styles.emailExample}`}
                placeholder={t("emailExample")}
                value={email}
                onChange={handleEmailChange}
                required
                id="emailInput"
              />
            </div>
            <div className={styles.passwordContainer}>
              <p className={styles.password}>{t("passwordHeading")} *</p>
              <div className={styles.passwordInputContainer}>
                <input
                  type={passwordShown ? "text" : "password"}
                  className={`${styles.passwordInput} ${styles.passwordExample}`}
                  placeholder={t("passwordExample")}
                  value={password}
                  onChange={handleInputPasswordChange}
                  required
                  id="passwordInput"
                />
                {passwordShown ? (
                  <VisibilityIcon onClick={togglePasswordVisibility} className={styles.eyeIcon} />
                ) : (
                  <VisibilityOffIcon onClick={togglePasswordVisibility} className={styles.eyeIcon} />
                )}
              </div>
              <Link to="/recoveryEmail" className={styles.buttonRecovery}>Forgot your password?</Link>
              {error && (
                <p className={styles.lineCheckValidPass}>
                  <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                  <span className={styles.errorMessage}>{t("loginError")}</span>
                </p>
              )}
            </div>

            <button className={styles.buttonContinue} type="button" onClick={handleClick} id="loginContinueButton">
              <div className={styles.buttonContinueText}>
                <span>{t("loginButtonContinue")}</span>
              </div>
            </button>

            <div className={styles.agreementTextContainer}>
              <div className={styles.agreementText}>
                {t("agreementText1")}
                <button type="button" onClick={() => handleOpenModal("terms")} className={styles.link} id="termsLink">
                  {t("terms-and-conditions")}
                </button>
                {t("agreementText2")}
                <button
                  type="button"
                  onClick={() => handleOpenModal("privacy")}
                  className={styles.link}
                  id="privacyPolicyLink"
                >
                  {t("privacy-policy")}
                </button>
                {i18n.language === "de" && t("agreementText3")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cookies />
    </div>
  );
}

export default Login;
