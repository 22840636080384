import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useDispatch, useSelector } from "react-redux";

import AvatarCropper from "../avatar_cropper/AvatarCropper";
import * as selector from "../selectors";
import {
  addSynthetic,
  loadAllAvatar,
  loadAllLanguage,
  loadAllVoice,
  uploadAvatar,
  loadAllSharedAvatars,
} from "../userSyntheticSlice";
import PreLoading from "../../../components/pre_loading/PreLoading";
import { selectUser } from "../../auth/selectors";

import styles from "./createSynthetic.module.css";
import ErrorWindow from "../../../components/modal_window/ErrorWindow";
import AdvancedSettings from "./settings/advance_settings/AdvancedSettings";
import { useDefaultSettings } from "../../../default_context/DefaultSettingsContext";
import AlignmentSettings from "./settings/alignment_settings/AlignmentSettings";

export default function CreateSynthetic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const avatars = useSelector(selector.selectAllAvatar);
  const sharedAvatars = useSelector(selector.selectAllSharedAvatar);
  const languages = useSelector(selector.selectAllLanguage);
  const voices = useSelector(selector.selectAllVoice);
  const isLoadingUploadAvatar = useSelector(selector.selectIsLoadingUploadAvatar);
  const isLoadingCreateSyn = useSelector(selector.selectIsLoadingCreateSyn);
  const isError = useSelector(selector.selectIsError);
  const [text, setText] = useState("");
  const [confirmWindowIsActive, setConfirmWindowIsActive] = useState(false);
  const [textChecked, setTextChecked] = useState(false);
  const [buttonPuls, setButtonPuls] = useState("");
  const {
    pulsarColor: defaultPulsarColor,
    pulsarSize: defaultPulsarSize,
    pulsarIntensive: defaultPulsarIntensive,
    buttonOptions: defaultButtonOptions,
    syntheticDefaults,
  } = useDefaultSettings();
  const [newSynthetic, setNewSynthetic] = useState({
    ...syntheticDefaults,
    pulsarColor: defaultPulsarColor,
    pulsarSize: defaultPulsarSize,
    pulsarIntensive: defaultPulsarIntensive,
    buttonOptions: defaultButtonOptions,
  });
  const audioUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public_voices/";
  const [currentFile, setCurrentFile] = useState("");
  const [checkDoneName, setCheckDoneName] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [openStandard, setStandard] = useState(null);
  const [showDropdownPicture, setShowDropdownPicture] = useState(false);
  const [checkDonePicture, setCheckDonePicture] = useState(null);
  const [activeBtnPicture, setActiveBtnPicture] = useState(null);
  const [checkDoneAlignment, setCheckDoneAlignment] = useState(null);
  const [showDropdownLanguage, setShowDropdownLanguage] = useState(false);
  const [checkDoneLanguage, setCheckDoneLanguage] = useState(null);
  const [activeBtnLanguage, setActiveBtnLanguage] = useState(null);
  const [showDropdownText, setShowDropdownText] = useState(false);
  const [showDropdownVoice, setShowDropdownVoice] = useState(false);
  const [checkDoneVoice, setCheckDoneVoice] = useState(null);
  const [activeBtnVoice, setActiveBtnVoice] = useState(null);
  const [activeImage, setActiveImage] = useState(null);
  const [audioPlay, setAudioPlay] = useState(false);
  const [provider, setProvider] = useState("elevenlabs");
  const [typeOfAvatar, setType] = useState(false);
  const [successfulAddSyn, setSuccessfulAddSyn] = useState(false);
  const [lang, setLang] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [showWarningAlignment, setShowWarningAlignment] = useState(false);

  function calculateCoinFromAudioDuration() {
    const AVERAGE_READING_SPEED_WORDS_PRE_MINUTE = 115;
    const words = text.trim().split(/\s+/);
    const wordCount = words.length;
    const SECONDS_PRE_MINUTE = 60;
    const SECONDS_IN_COIN = 15;
    const durationInSeconds = wordCount / (AVERAGE_READING_SPEED_WORDS_PRE_MINUTE / SECONDS_PRE_MINUTE);
    const roundDurationInSeconds = Math.round(durationInSeconds);
    if (Math.ceil(roundDurationInSeconds / SECONDS_IN_COIN) === 0) {
      return 1;
    }
    return Math.ceil(roundDurationInSeconds / SECONDS_IN_COIN);
  }

  function checkBalanceBeforeCreate() {
    return calculateCoinFromAudioDuration() <= user.balance;
  }

  function play(id, audio) {
    setAudioPlay(!audioPlay);
    setCurrentFile(audio);
    setButtonPuls(id);
  }

  function onAudioEnded() {
    setAudioPlay(false);
    setButtonPuls("");
  }

  const checkAllDone = () => {
    let allDone = true;

    if (checkDoneAlignment === null || !checkDoneAlignment) {
      setCheckDoneAlignment(false);
      setShowWarningAlignment(true);
      allDone = false;
    }
    if (checkDoneName === null || !checkDoneName) {
      setCheckDoneName(false);
      allDone = false;
    }
    if (checkDoneLanguage === null || !checkDoneLanguage) {
      setCheckDoneLanguage(false);
      allDone = false;
    }
    if (checkDonePicture === null || !checkDonePicture) {
      setCheckDonePicture(false);
      allDone = false;
    }
    if (checkDoneVoice === null || !checkDoneVoice) {
      setCheckDoneVoice(false);
      allDone = false;
    }
    if (text === "" || text !== newSynthetic.text) {
      setTextChecked(true);
      allDone = false;
    }
    return allDone;
  };

  const handleClickNavigate = (path, buttonId) => {
    navigate(path);
    setActiveButton(buttonId);
  };

  const handleEdit = (event) => {
    const { name: key, value } = event.target;
    setNewSynthetic((prevNewSynthetic) => ({
      ...prevNewSynthetic,
      [key]: value,
    }));
  };

  const handleOpenModal = (modal) => {
    setOpenModal(modal);
  };

  const handleOpenStandard = (modal) => {
    setStandard(modal);
  };

  const handleClickBtn = (currSection, buttonId) => {
    if (currSection === "pictureSection") {
      setActiveBtnPicture(buttonId);
    }
    if (currSection === "languageSection") {
      setActiveBtnLanguage(buttonId);
    }
    if (currSection === "voiceSection") {
      setActiveBtnVoice(buttonId);
    }
  };

  function setVoice(providerName, voiceName, voiceId) {
    setCheckDoneVoice(true);
    setProvider(providerName);
    handleClickBtn("voiceSection", `btnVoice${voiceName}`);
    setNewSynthetic({ ...newSynthetic, voiceId });
  }

  const handleSelectImage = (avatarUrl) => {
    setNewSynthetic({ ...newSynthetic, avatarId: activeImage });
    setOpenModal(null);
    if (openStandard != null) {
      setStandard(null);
      setType(true);
    }
    setActiveBtnPicture(null);
    setSelectedAvatar(avatarUrl);
    setCheckDonePicture(true);
  };

  const getAvatarById = () => {
    if (!typeOfAvatar) {
      const findedAva = avatars.find((ava) => ava.id === newSynthetic.avatarId);
      return findedAva.binaryData;
    }
    const findedAva = sharedAvatars.find((ava) => ava.id === newSynthetic.avatarId);
    return findedAva.binaryData;
  };

  const handleCreateConfirmWindow = () => {
    if (checkAllDone()) {
      setConfirmWindowIsActive(!confirmWindowIsActive);
    }
  };

  const handleCancel = () => {
    if (checkAllDone()) {
      setConfirmWindowIsActive(!confirmWindowIsActive);

      setNewSynthetic({
        ...syntheticDefaults,
        pulsarColor: defaultPulsarColor,
        pulsarSize: defaultPulsarSize,
        pulsarIntensive: defaultPulsarIntensive,
        buttonOptions: defaultButtonOptions,
      });

      setText("");
      setCheckDoneName(false);
      setCheckDoneLanguage(false);
      setCheckDoneAlignment(false);
      setCheckDoneVoice(false);
      setCheckDonePicture(false);
      setTextChecked(true);

      document.getElementById("nameInputSynthetic").value = "";

      setShowDropdownLanguage(false);
      setShowDropdownPicture(false);
      setShowDropdownText(false);
      setShowDropdownVoice(false);

      setActiveBtnPicture(null);
      setActiveImage(null);
      setActiveBtnLanguage(null);
      setActiveBtnVoice(null);
    }
  };

  const handleCreate = useCallback(async () => {
    await dispatch(addSynthetic({ newSynthetic, provider }));
    setSuccessfulAddSyn(true);
  }, [dispatch, newSynthetic, provider]);

  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n > 0) {
      n -= 1;
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleCrop = useCallback(
    async (croppedData) => {
      const blob = dataURLToBlob(croppedData);
      const file = new File([blob], "avatar.png", { type: blob.type });

      const dispatchResult = await dispatch(uploadAvatar(file));
      if (uploadAvatar.fulfilled.match(dispatchResult)) {
        setOpenModal(null);
        setActiveBtnPicture(null);

        setNewSynthetic({ ...newSynthetic, avatarId: dispatchResult.payload.id });
        setCheckDonePicture(true);
      }
    },
    [dispatch, newSynthetic]
  );

  useEffect(() => {
    if (isError) {
      setConfirmWindowIsActive(false);
    }
    if (!isError && successfulAddSyn) {
      navigate("/account/mySynthetic");
    }
  }, [isError, successfulAddSyn, navigate]);

  useEffect(() => {
    dispatch(loadAllAvatar());
    dispatch(loadAllLanguage());
    dispatch(loadAllVoice());
    dispatch(loadAllSharedAvatars());
  }, [dispatch]);

  const [alignment] = useState({
    left: "3",
    right: "none",
    bottom: "5",
    checked: true,
    active: "",
  });

  const handleAlignmentChange = (newAlignment) => {
    setNewSynthetic((prevSynthetic) => ({
      ...prevSynthetic,
      left: newAlignment.left,
      right: newAlignment.right,
      bottom: newAlignment.bottom,
    }));
    setShowWarningAlignment(false);
  };

  return (
    <div className={styles.mainBox}>
      <div className={styles.leftBarContainer}>
        <div className={styles.backContainer}>
          <img src="/circle.webp" alt="buttonBack" />
        </div>
        <div>
          <button
            className={`${styles.buttonBack} ${activeButton === "buttonBack" ? styles.buttonBackPressed : ""}`}
            type="button"
            onClick={() => handleClickNavigate("/account/mySynthetic", "buttonBack")}
          >
            <div className={styles.buttonBackContainer}>
              <WestIcon style={{ color: "#1570ef" }} />
              <div className={styles.buttonBackTextContainer}>
                <span>back</span>
              </div>
            </div>
          </button>
        </div>
      </div>
      {isError && <ErrorWindow open />}
      {isLoadingCreateSyn ? (
        <PreLoading />
      ) : (
        <div className={styles.syntheticCard}>
          <h1>Creation of a new synthetic</h1>
          <div className={styles.nameBlock}>
            {checkDoneName != null &&
              (checkDoneName ? (
                <DoneIcon style={{ color: "green" }} />
              ) : (
                <WarningAmberIcon style={{ color: "#EC4A0A" }} />
              ))}
            <span className={styles.nameText}>Name of synthetic:</span>
            <input
              className={styles.nameInput}
              type="text"
              name="name"
              id="nameInputSynthetic"
              onChange={(e) => {
                handleEdit(e);
                if (e.target.value !== "") {
                  setCheckDoneName(true);
                } else {
                  setCheckDoneName(false);
                }
              }}
            />
          </div>
          <div>
            <button
              className={styles.btnDropdown}
              id="btnDropdownPicture"
              type="button"
              onClick={() => setShowDropdownPicture(!showDropdownPicture)}
            >
              <span className={styles.textIconInButton}>
                {checkDonePicture != null &&
                  (checkDonePicture ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                  ))}
                1. Add an avatar
              </span>
              {showDropdownPicture ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            {showDropdownPicture && (
              <div className={`${styles.dropdownElement} ${styles.picture}`}>
                <button
                  className={`${styles.btn} ${activeBtnPicture === "btnPictureUpload" ? styles.activeBtnByCreate : ""}`}
                  id="btnPictureUpload"
                  type="button"
                  onClick={() => {
                    handleClickBtn("pictureSection", "btnPictureUpload");
                    handleOpenModal("uploadModal");
                  }}
                >
                  <UploadIcon />
                  Upload new avatar
                </button>
                {openModal === "uploadModal" && (
                  <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                      {isLoadingUploadAvatar ? (
                        <PreLoading />
                      ) : (
                        <>
                          <h1>Upload new avatar</h1>
                          <div className={styles.requirementsBox}>
                            <div className={styles.requirementsTextBox}>
                              <p className={styles.requirementsTextHead}>Photo requirements for optimized results:</p>
                              <p className={styles.requirementsText}>
                                <DoneIcon style={{ color: "green" }} />
                                <span>Medium shot, frontal facing</span>
                              </p>
                              <p className={styles.requirementsText}>
                                <DoneIcon style={{ color: "green" }} />
                                Neutral facial expression, closed mouth
                              </p>
                              <p className={styles.requirementsText}>
                                <DoneIcon style={{ color: "green" }} />
                                File Format: png, jpeg, jpg
                              </p>
                              <p className={styles.requirementsText}>
                                <DoneIcon style={{ color: "green" }} />
                                Recommended image size is - 512X512 pixels
                              </p>
                              <p className={styles.requirementsText}>
                                <DoneIcon style={{ color: "green" }} />
                                Good and solid lighting
                              </p>
                              <p className={styles.requirementsText}>
                                <DoneIcon style={{ color: "green" }} />
                                Size - up to 10Mb
                              </p>
                              <p className={styles.requirementsText}>
                                <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                                No face occlusions
                              </p>
                            </div>
                            <img className={styles.exampleImg} src="/syntheticAvatar.webp" alt="example" />
                          </div>
                          <AvatarCropper onCrop={handleCrop} />
                          <button
                            className={`${styles.btn} ${styles.modalUploadCancelBtn}`}
                            type="button"
                            id="modalUploadCancelBtn"
                            onClick={() => {
                              setOpenModal(null);
                              setActiveBtnPicture(null);
                            }}
                          >
                            Cancel
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <button
                  className={`${styles.btn} ${activeBtnPicture === "btnPictureSelect" ? styles.activeBtnByCreate : ""}`}
                  id="btnPictureSelect"
                  type="button"
                  onClick={() => {
                    handleClickBtn("pictureSection", "btnPictureSelect");
                    handleOpenModal("selectModal");
                  }}
                >
                  <DownloadDoneIcon />
                  My avatars
                </button>
                <button
                  className={`${styles.btn} ${activeBtnPicture === "btnPictureSelect" ? styles.activeBtnByCreate : ""}`}
                  id="btnPictureSelect"
                  type="button"
                  onClick={() => {
                    handleClickBtn("pictureSection", "btnPictureSelect");
                    handleOpenStandard("selectModal");
                  }}
                >
                  <DownloadDoneIcon />
                  Standard
                </button>
                {openModal === "selectModal" && (
                  <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                      <h1>Select an existing avatar</h1>
                      <div className={styles.avatarBox}>
                        {avatars.length !== 0
                          ? avatars.map((ava) => (
                              <div className={styles.avatarCard} key={ava.id}>
                                <button
                                  className={`${styles.selectAvatar} ${activeImage === ava.id && styles.activeImage}`}
                                  id={`selectBtn${ava.id}`}
                                  type="button"
                                  onClick={() => setActiveImage(ava.id)}
                                >
                                  <img src={`data:image/*;base64,${ava.binaryData}`} alt={ava.name} />
                                </button>
                              </div>
                            ))
                          : "No avatars uploaded yet"}
                      </div>
                      <p className={styles.buttonLineElement}>
                        {activeImage === null ? (
                          <button className={`${styles.btn} ${styles.noActiveBtn}`} type="button" id="modalSelectBtn">
                            Select
                          </button>
                        ) : (
                          <button
                            className={`${styles.btn} ${styles.modalSelectBtn}`}
                            type="button"
                            id="modalSelectBtn"
                            onClick={() => {
                              const selectedAvatarData = avatars.find((ava) => ava.id === activeImage);
                              if (selectedAvatarData) {
                                const avatarUrl = `data:image/*;base64,${selectedAvatarData.binaryData}`;
                                handleSelectImage(avatarUrl);
                              }
                            }}
                          >
                            Select
                          </button>
                        )}

                        <button
                          className={`${styles.btn} ${styles.modalCancelBtn}`}
                          type="button"
                          id="modalUploadCancelBtn"
                          onClick={() => {
                            setOpenModal(null);
                            setActiveBtnPicture(null);
                            setActiveImage(null);
                          }}
                        >
                          Cancel
                        </button>
                      </p>
                    </div>
                  </div>
                )}
                {openStandard === "selectModal" && (
                  <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                      <h1>Select an existing avatar</h1>
                      <div className={styles.avatarBox}>
                        {sharedAvatars.length !== 0
                          ? sharedAvatars.map((ava) => (
                              <div className={styles.avatarCard} key={ava.id}>
                                <button
                                  className={`${styles.selectAvatar} ${activeImage === ava.id && styles.activeImage}`}
                                  id={`selectBtn${ava.id}`}
                                  type="button"
                                  onClick={() => setActiveImage(ava.id)}
                                >
                                  <img src={`data:image/*;base64,${ava.binaryData}`} alt={ava.name} />
                                </button>
                              </div>
                            ))
                          : "No avatars uploaded yet"}
                      </div>
                      <p className={styles.buttonLineElement}>
                        {activeImage === null ? (
                          <button className={`${styles.btn} ${styles.noActiveBtn}`} type="button" id="modalSelectBtn">
                            Select
                          </button>
                        ) : (
                          <button
                            className={`${styles.btn} ${styles.modalSelectBtn}`}
                            type="button"
                            id="modalSelectBtn"
                            onClick={handleSelectImage}
                          >
                            Select
                          </button>
                        )}

                        <button
                          className={`${styles.btn} ${styles.modalCancelBtn}`}
                          type="button"
                          id="modalUploadCancelBtn"
                          onClick={() => {
                            setStandard(null);
                            setActiveBtnPicture(null);
                            setActiveImage(null);
                          }}
                        >
                          Cancel
                        </button>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <AlignmentSettings
            onAlignmentChange={handleAlignmentChange}
            defaultAlignment={alignment}
            checkDoneAlignment={checkDoneAlignment}
            setCheckDoneAlignment={setCheckDoneAlignment}
            showWarning={showWarningAlignment}
          />

          <div>
            <button
              className={styles.btnDropdown}
              id="btnDropdownLanguage"
              type="button"
              onClick={() => setShowDropdownLanguage(!showDropdownLanguage)}
            >
              <span className={styles.textIconInButton}>
                {checkDoneLanguage != null &&
                  (checkDoneLanguage ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                  ))}
                3. Select language
              </span>
              {showDropdownLanguage ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            {showDropdownLanguage && (
              <div className={`${styles.dropLang} ${styles.language}`}>
                {languages
                  ? languages.map((lan) => (
                      <button
                        key={lan.id}
                        className={`${styles.btn} ${activeBtnLanguage === `btnLanguage${lan.name}` ? styles.activeBtnByCreate : ""}`}
                        id={`btnLanguage${lan.name}`}
                        type="button"
                        onClick={() => {
                          if (lan.name === "Finnish") {
                            setLang("fi_");
                          } else {
                            setLang("");
                          }
                          setCheckDoneLanguage(true);
                          handleClickBtn("languageSection", `btnLanguage${lan.name}`);
                          setNewSynthetic({ ...newSynthetic, languageId: lan.id });
                        }}
                      >
                        {lan.name}
                      </button>
                    ))
                  : "Not found languages"}
              </div>
            )}
          </div>
          <div>
            <button
              className={styles.btnDropdown}
              id="btnDropdownText"
              type="button"
              onClick={() => setShowDropdownText(!showDropdownText)}
            >
              <span className={styles.textIconInButton}>
                {textChecked &&
                  (text === newSynthetic.text && text !== "" ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                  ))}
                4. Add text
              </span>
              {showDropdownText ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            {showDropdownText && (
              <div className={`${styles.dropdownElement} ${styles.text}`}>
                <textarea id="textareaInput" value={text} maxLength={3875} onChange={(e) => setText(e.target.value)} />
                <div className={styles.requirementsInfo}>
                  {text.length !== 0 && text.length <= 3875 && text !== "" ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                  )}
                  {text.length}/3875 characters
                </div>
                {text !== newSynthetic.text && text.length !== 0 ? (
                  <button
                    className={`${styles.btn}`}
                    id="btnTextSave"
                    type="button"
                    onClick={() => {
                      handleClickBtn("textSection", "btnTextSave");
                      setNewSynthetic({ ...newSynthetic, text });
                      setTextChecked(true);
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className={`${styles.btn} ${styles.noActiveBtn}`}
                    id="btnTextSave"
                    type="button"
                    onClick={() => {
                      handleClickBtn("textSection", "btnTextSave");
                      setNewSynthetic({ ...newSynthetic, text });
                      setTextChecked(true);
                    }}
                  >
                    Save
                  </button>
                )}
              </div>
            )}
          </div>
          <div>
            <button
              className={styles.btnDropdown}
              id="btnDropdownVoice"
              type="button"
              onClick={() => setShowDropdownVoice(!showDropdownVoice)}
            >
              <span className={styles.textIconInButton}>
                {checkDoneVoice != null &&
                  (checkDoneVoice ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                  ))}
                5. Select a voice
              </span>
              {showDropdownVoice ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            {showDropdownVoice && (
              <div className={`${styles.voicesElement} ${styles.voice}`}>
                {voices
                  ? voices.map(
                      (v) =>
                        (lang !== "fi_" || v.provider !== "microsoft") && (
                          <React.Fragment key={v.id}>
                            {" "}
                            <button
                              className={`${styles.btn} ${activeBtnVoice === `btnVoice${v.name}` ? styles.activeBtnByCreate : ""}`}
                              id={`btnVoice${v.name}`}
                              type="button"
                              onClick={() => {
                                setVoice(v.provider, v.name, v.id);
                              }}
                            >
                              <RecordVoiceOverIcon />
                              {v.name}
                            </button>
                            <div className={styles.hear}>
                              <div
                                className={`${styles.iconWrapper} ${v.id === buttonPuls && audioPlay ? styles.pulsate : ""}`}
                              >
                                <VolumeUpIcon
                                  className={styles.volumeIcon}
                                  onClick={() => {
                                    play(v.id, `${audioUrl + lang + v.name}.mp3`);
                                  }}
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        )
                    )
                  : "No found voices"}
                {audioPlay && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <audio autoPlay onEnded={onAudioEnded}>
                    <source src={currentFile} type="audio/mpeg" />
                  </audio>
                )}
              </div>
            )}
          </div>

          <AdvancedSettings
            selectedAvatar={selectedAvatar || "/syntheticAvatar.webp"}
            onSaveSettings={(updatedSettings) => {
              setNewSynthetic((prev) => ({ ...prev, ...updatedSettings }));
            }}
          />

          <button
            className={`${styles.btn} ${styles.createBtnActive}`}
            id="createBtn"
            type="button"
            onClick={handleCreateConfirmWindow}
          >
            Create
          </button>
          {confirmWindowIsActive && (
            <>
              <button type="button" className={styles.confirmWindowBody} onClick={handleCreateConfirmWindow}>
                {" "}
              </button>
              <div className={styles.confirmWindow}>
                <div className={styles.avatarBoxByConfirm}>
                  <img
                    className={styles.avaByConfirm}
                    src={`data:image/*;base64,${getAvatarById()}`}
                    alt="confirmAva"
                  />
                  <p>{newSynthetic.name}</p>
                </div>
                <div className={styles.calcByConfirmBox}>
                  <div>
                    <h3>Calculation coins</h3>
                    <p className={`${styles.coinsCalcText} ${!checkBalanceBeforeCreate() && styles.noAccess}`}>
                      {calculateCoinFromAudioDuration()} Coin *
                    </p>
                  </div>
                  <div className={styles.btnBoxByConfirm}>
                    {checkBalanceBeforeCreate() && (
                      <button
                        className={styles.btnApplyByConfirm}
                        id="applyBtnByConfirm"
                        type="button"
                        onClick={handleCreate}
                      >
                        Apply
                      </button>
                    )}
                    <button
                      className={styles.btnEditByConfirm}
                      id="applyBtnByConfirm"
                      type="button"
                      onClick={() => setConfirmWindowIsActive(false)}
                    >
                      Edit
                    </button>
                    <button
                      className={styles.btnCancelByConfirm}
                      id="cancelBtnByConfirm"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    {checkBalanceBeforeCreate() ? (
                      <p>
                        * Approximate number of coins, the exact number will be written off after successful generation.
                      </p>
                    ) : (
                      <p>* The cost of creating a synthetic exceeds your current balance.</p>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
