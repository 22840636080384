import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react";

import TollIcon from "@mui/icons-material/Toll";
import Button from "../../components/button/Button";
import { logout } from "../auth/authSlice";
import { selectUser } from "../auth/selectors";

import styles from "./accountNavbar.module.css";

function AccountNavbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [balance, setBalance] = useState(user?.balance);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const getColorFromPricingId = () => {
    switch (user?.pricingId) {
      case 1:
        return "#F2F4F7";
      case 2:
        return "#D1E9FF";
      case 3:
        return "#EBE9FE";
      default:
        return "F2F4F7";
    }
  };

  const handleClick = useCallback(async () => {
    navigate("/pricing");
  }, [navigate]);

  return (
    <div className={styles.navbarBox}>
      <Link to="/" className={location.pathname === "/" ? styles.logoBoxHome : styles.logoBoxOther} id="homeLogoLink">
        <img
          className={styles.logo}
          src={location.pathname === "/" ? `/Logo-home.webp` : `/Logo-other.webp`}
          alt="synthetic-icon"
          id="navbarLogo"
        />
        <span className={styles.textLogo}>synthetic</span>
      </Link>
      <nav className={styles.signBox}>
        <div className={styles.balanceBox} style={{ backgroundColor: getColorFromPricingId() }}>
          <TollIcon />
          <span className={styles.balanceText}>{balance} coins</span>
        </div>
        <Button
          buttonName={t("addCoins")}
          buttonType="submit"
          onClick={handleClick}
          className={styles.addCoinsButton}
        />
        <Link to="profile/privateDate" className={styles.avatarLink} id="profileLink">
          <span>{user?.email}</span>
          <img className={styles.avatarImage} src="/avatar.webp" alt="Avatar" />
        </Link>
        <button
          type="button"
          id="logoutBtn"
          className={location.pathname === "/" ? styles.signUpHome : styles.signUpOther}
          onClick={handleLogout}
        >
          {t("logOut")}
        </button>
      </nav>
    </div>
  );
}

export default AccountNavbar;
